import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const OrderTracking = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} className={classes.root}>
        <Typography variant="h5" align="center" gutterBottom>
          Your Order Has Shipped!
        </Typography>
        <Typography variant="body1" paragraph>
          We’ve got great news! Your order is on the way.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Order #16104535320</strong>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Date placed: November 13, 2023
        </Typography>
      </Paper>
    </Container>
  );
};

export default OrderTracking;
