import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { AuthContext } from '../auth/auth';

const Review = ({
  profileData,
  selectedCounty,
  selectedSubCounty,
  selectedLocality,
  deliveryOption,
  phoneNumber,
  selectedPostaBranch,
  selectedApproximateLocation,
  deliveryCost, 
}) => {
  const { cartList } = useContext(AuthContext);
  const addresses = [selectedLocality, selectedSubCounty, selectedCounty, 'Kenya'];

  // Calculate the total product cost
  const totalProductCost = cartList.reduce((total, product) => total + parseFloat(product.price) * product.quantity, 0);

  // Calculate the total amount including delivery cost
  const totalAmount = totalProductCost + (deliveryCost ? Number(deliveryCost) : 0);

  return (
    <React.Fragment>
      {/* Order Summary */}
      <Typography variant="h6" gutterBottom>
        <strong> Order Summary </strong>
      </Typography>
      <List disablePadding>
        {cartList.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <img src={product.image1} alt={product.title} style={{ maxWidth: '100px' }} />
            <ListItemText primary={product.title} secondary={product.description} />
            {/* <Typography variant="body2">{`Ksh ${product.price} x ${product.quantity}`}</Typography><br></br> */}
            <Typography variant="body2">{`Total: Ksh ${(product.price * product.quantity).toFixed(2)}`}</Typography>
          </ListItem>
        ))}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total Products Cost" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {`Ksh ${totalProductCost.toFixed(2)}`}
          </Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Delivery Cost" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {`Ksh ${deliveryCost ? Number(deliveryCost).toFixed(2) : 'Calculating...'}`}
          </Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total Amount to be Paid" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {`Ksh ${totalAmount.toFixed(2)}`}
          </Typography>
        </ListItem>
      </List>

      {/* Shipping Information */}
      <hr style={{ margin: '16px 0' }} />
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}><strong>
        Shipping Details</strong>
      </Typography>
      <Typography gutterBottom>
        <strong>Customer Name:</strong> {`${profileData?.first_name} ${profileData?.last_name}`}
      </Typography>
      <Typography gutterBottom>
        <strong>Address:</strong> {`${addresses.join(', ')}`}
      </Typography>
      <Typography gutterBottom>
        <strong>Delivery Option:</strong> {`${deliveryOption}`}
      </Typography>
      <Typography gutterBottom>
        <strong>Approximate Location:</strong> {`${selectedApproximateLocation}`}
      </Typography>
      <Typography gutterBottom>
        <strong>Customer's Nearest Post Office:</strong> {`${selectedPostaBranch?.name}`}
      </Typography>

    </React.Fragment>
  );
};

export default Review;
