import React, { useContext, useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from './addressform';
import PaymentForm from './paymentform';
import Review from './review';
import Dialog from '@mui/material/Dialog'; // Import Dialog component
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CustomSnackbar from './CustomSnackbar'; // Import your Snackbar component
import { AuthContext } from '../auth/auth';
import Alert from '@mui/material/Alert';

function Checkout() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Shipping address', 'Payment details', 'Review your order'];
  
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [locations, setLocations] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState('');
  const [selectedSubCounty, setSelectedSubCounty] = useState('');
  const [selectedLocality, setSelectedLocality] = useState('');
  const [formData, setFormData] = useState({});
  const { authTokens, cartList,logoutUser, apiUrl } = useContext(AuthContext);
  const [selectedBranch, setSelectedBranch] = useState('');
  
  const [branchSuggestions, setBranchSuggestions] = useState([]);
  const [deliveryOption, setDeliveryOption] = useState('Door to Door');
  const [selectedPostaBranch, setSelectedPostaBranch] = useState('');
  const [selectedApproximateLocation, setSelectedApproximateLocation] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [networkCode, setNetworkCode] = useState('');
  const [deliveryCost, setDeliveryCost] = useState(null);


  useEffect(() => {
    // Create a new FormData object and append the data from the specified state variables
    const newFormData = new FormData();  
    // Append selectedPlace to FormData if it is not null
    if (selectedPlace) {
      newFormData.append('selectedPlace', selectedPlace);
    }
  
    // Append other state variables to FormData
    newFormData.append('profileData', JSON.stringify(profileData));
    newFormData.append('locations', JSON.stringify(locations));
    newFormData.append('selectedCounty', selectedCounty);
    newFormData.append('selectedSubCounty', selectedSubCounty);
    newFormData.append('selectedLocality', selectedLocality);
    newFormData.append('selectedApproximateLocation', selectedApproximateLocation);
    newFormData.append('phoneNumber', phoneNumber);
    newFormData.append('networkCode', networkCode)
    newFormData.append('deliveryOption', deliveryOption);
    newFormData.append('selectedPostaBranch', selectedPostaBranch);
    
    // Include cartList with quantity
    newFormData.append('cartList', JSON.stringify(cartList.map(item => ({
      product_serial: item.product_serial,
      quantity: item.quantity,
    
    }))));
    // Append other state variables as needed
  
    // Update the FormData state variable with the new FormData object
    setFormData(newFormData);
}, [selectedPlace, profileData, locations, selectedCounty, selectedSubCounty, selectedLocality, selectedApproximateLocation, phoneNumber, deliveryOption, selectedPostaBranch, cartList]);
  
  // Repeat the above pattern for all the other state variables you want to track
  
  // Add state variables for the dialogs and Snackbar
  const [showDialog, setShowDialog] = useState(false);
  const [showSecondDialog, setShowSecondDialog] = useState(false);
  const [showThirdDialog, setShowThirdDialog] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [apiCallMade, setApiCallMade] = useState(false);

  

  // Functions to open and close the dialogs
  const openDialog = (dialogType) => {
    switch (dialogType) {
      case 'first':
        setShowDialog(true);
        break;
      case 'second':
        setShowSecondDialog(true);
        break;
      case 'third':
        setShowThirdDialog(true);
        break;
      default:
        break;
    }
  };

  const closeDialog = (dialogType) => {
    switch (dialogType) {
      case 'first':
        setShowDialog(false);
        break;
      case 'second':
        setShowSecondDialog(false);
        break;
      case 'third':
        setShowThirdDialog(false);
        break;
      default:
        break;
    }
  };

  const openContinueDialog = () => {
    setShowContinueDialog(true);
  };
  
  const closeContinueDialog = () => {
    setShowContinueDialog(false);
  };
  // Add state variables for API response and loading indicator
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);


  // Function to open the Snackbar
  const openSnackbar = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setShowSnackbar(true);
  };

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCountyChange = (event) => {
    const county = event.target.value;
    setSelectedCounty(county);
    setSelectedSubCounty('');
    setSelectedLocality('');
  };

  useEffect(() => {
    console.log('approximation', selectedApproximateLocation);
    console.log('posta', selectedPostaBranch);
  }, [selectedPostaBranch, selectedApproximateLocation]);

  const handleSubCountyChange = (event) => {
    const subcounty = event.target.value;
    setSelectedSubCounty(subcounty);
    setSelectedLocality('');
  };

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    console.log(`Latitude: ${lat}, Longitude: ${lng}`);
  };

  const [validationError, setValidationError] = useState('');


  const validateAllFields = () => {
   
    if (!profileData.first_name || !locations || !selectedCounty || !selectedSubCounty || !selectedLocality) {
      setValidationError('Please fill in all required fields.'); // Set the error message
      return false;
    }
    setValidationError(''); // Reset the error message
    return true;
  };
  const handleNext = async () => {
    // Add your validation logic here
    if (validateAllFields()) {
      try {
        // Make the API call only if it hasn't been made yet
        if (activeStep === 0 && !apiCallMade) {
          const payload = {
            delivery_method: deliveryOption,
            locationsuggestion: selectedApproximateLocation,
            destination_post_office: selectedPostaBranch.name,
            products: cartList.map(item => ({
              product_serial: item.product_serial,
              product_weight: item.product_weight * item.quantity,
              approximate_location: item.approximate_location, 
              nearest_post_office: item.nearest_post_office,
              quantity: item.quantity, 
            }))
          };
  
          const response = await fetch(`${apiUrl}/api/calculate_delivery_fee/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authTokens.access}`,
            },
            body: JSON.stringify(payload),
          });
  
          if (response.ok) {
            const data = await response.json();
            console.log('API Response:', data);
  
            // Store the result for this item
            const deliveryFee = data.delivery_fee;
            setDeliveryCost(deliveryFee); // Assuming the API directly returns the total delivery fee
          } else {
            const errorData = await response.json();
            console.error('Failed to calculate delivery fee:', errorData);
            // Handle error, display message, etc.
          }
        }
  
        // Set the flag to indicate that the API call has been made
        setApiCallMade(true);
  
        // Proceed to the next step automatically
        if (activeStep !== steps.length - 1) {
          setActiveStep(activeStep + 1);
        } else {
          // If it's the last step, open the 'third' dialog or perform additional logic
          openDialog('third');
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error);
        // Handle unexpected errors
      }
    } else {
      alert('Please input all the required fields*.');
      console.error('Please fill in all required fields.');
    }
  };
  
  
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  
  const handleContinueOrders = () => {
    handleClearCart();
    setDashboardType('Customer');
    navigate('/dashboard/product_orders', { replace: true });
  };

  const handleContinueShopping = () => {
    handleClearCart();
    navigate('/products', { replace: true });
  };

  const ClearCart= () => {
    handleClearCart();
    setShowDialog(false);
    setShowSecondDialog(false);

  };

 

  const handleConfirmCheckout = async (e) => {
    e.preventDefault();

    const updatedCartList = cartList.map(item => ({
      ...item,
      delivery_option: deliveryOption,
      destination_office: selectedPostaBranch.name,
      mobile_number: phoneNumber,
      network_code: networkCode,
      location_suggestion: selectedApproximateLocation,
  }));
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify(updatedCartList),
    };
  
    try {
      const response = await fetch(`${apiUrl}/api/product_orders/`, requestOptions);
      const data = await response.json();
  
      console.log('API Response:', data);
  
      if (response.status === 400 || response.status === 424) {
        const errorMessage = Object.values(data).flat().join(', ');
        console.log('Error Message:', errorMessage);
        openSnackbar(errorMessage, 'error');
      } else if (response.status === 201) {
        const orderNo = data.order_no;
        localStorage.setItem('orderNo', orderNo);
        
  
        // Display success message in Snackbar
        openSnackbar('Order placed successfully!', 'success');
  
        // Navigate to OrderStatus with orderNo
        navigate(`/order-status/${orderNo}`, { state: { orderNo } });
  
        // Optionally, open the continue dialog
        openContinueDialog();
  
        // Close the 'third' dialog after confirming the order
        closeDialog('third');
      } else {
        throw new Error('Unexpected error');
      }
    } catch (error) {
      console.error(error);
      openSnackbar('An unexpected error occurred.', 'error');
    }
  };
  
  
  const displayApiResponseAlert = () => {
    if (apiResponse) {
      // Customize the alert content based on your API response structure
      const alertMessage = `API Response: ${JSON.stringify(apiResponse, null, 2)}`;
      return (
        <Alert severity="info" sx={{ mt: 2 }}>
          {alertMessage}
        </Alert>
      );
    }
    return null;
  };

  const handleClearCart = () => {
    // Logic to clear the cart
  };
  
  const setDashboardType = (type) => {
    // Logic to set the dashboard type
  };
  
  const navigate = (path, options) => {
    // Logic to navigate to a different route
  };
  
  // ...
  
  // Then in your component:
  
 
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            selectedPlace={selectedPlace}
            profileData={profileData}
            setLocations={setLocations}
            setProfileData={setProfileData}
            locations={locations}
            setSelectedCounty={setSelectedCounty}
            setSelectedLocality={setSelectedLocality}
            setSelectedSubCounty={setSelectedSubCounty}
            selectedCounty={selectedCounty}
            selectedSubCounty={selectedSubCounty}
            setDeliveryOption={setDeliveryOption}
            selectedLocality={selectedLocality}
            formData={formData}
            authTokens={authTokens}
            logoutUser={logoutUser}
            apiUrl={apiUrl}
            selectedPostaBranch={selectedPostaBranch}
            setSelectedPostaBranch={setSelectedPostaBranch}
            selectedApproximateLocation={selectedApproximateLocation}
            setSelectedApproximateLocation={setSelectedApproximateLocation}
            selectedBranch={selectedBranch}
            branchSuggestions={branchSuggestions}
            deliveryOption={deliveryOption}
            handleInputChange={handleInputChange}
            handleCountyChange={handleCountyChange}
            handleSubCountyChange={handleSubCountyChange}
            handlePlaceSelect={handlePlaceSelect}
            openDialog={openDialog} // Pass openDialog function to the AddressForm component
          />
        );
      case 1:
        return (
          <PaymentForm
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            networkCode={networkCode}
            setNetworkCode={setNetworkCode}
            openDialog={openDialog} 
          />
        );
      case 2:
        return (
          <Review
            selectedPlace={selectedPlace}
            profileData={profileData}
            locations={locations}
            selectedCounty={selectedCounty}
            selectedSubCounty={selectedSubCounty}
            selectedLocality={selectedLocality}
            formData={formData}
            authTokens={authTokens}
            logoutUser={logoutUser}
            apiUrl={apiUrl}
            selectedPostaBranch={selectedPostaBranch}
            deliveryOption={deliveryOption}
            phoneNumber={phoneNumber}
            networkCode={networkCode}
            selectedApproximateLocation={selectedApproximateLocation}
            openDialog={openDialog} // Pass openDialog function to the Review component
            openSnackbar={openSnackbar} // Pass openSnackbar function to the Review component
            deliveryCost={deliveryCost}
          />
        );
        case 3:
        return (
          <PaymentForm
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            networkCode={networkCode}
            setNetworkCode={setNetworkCode}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />

      <Container component="main" maxWidth="sm" sx={{ marginBottom: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
             
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                </Button>
              </Box>
              {validationError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {validationError}
              </Alert>
            )}
              {/* {validationError && (
               <Alert severity="error">{validationError}</Alert> // Display validation error message
              )} */}
            </React.Fragment>
          )}
        </Paper>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            {' © '}
            <Link color="inherit" href="https://www.icta.go.ke/">
              {new Date().getFullYear()} Copyright ICT Authority. All Rights Reserved.
            </Link>
          </Typography>
        </Box>
      </Container>

      {/* Dialog for confirming the order */}
      <Dialog open={showThirdDialog} onClose={() => closeDialog('third')}>
        <DialogTitle>Proceed to Orders</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to place orders to respective merchants?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => closeDialog('third')}>
            Cancel
          </Button>
          <Button onClick={handleConfirmCheckout} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* Display API response */}
      {apiResponse && (
        <div>
          <h2>API Response:</h2>
          <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
        </div>
      )}

      {/* Display loading indicator if the API call is in progress */}
      {loading && <p>Loading...</p>}
      <Dialog open={showContinueDialog} onClose={closeContinueDialog}>
  <DialogTitle>Next Step</DialogTitle>
  <DialogContent>
    <DialogContentText>
     Continue to order status, view orders or continue shopping
    </DialogContentText>
  </DialogContent>
  <DialogActions>    
  <Button color="primary" onClick={() => window.location.href = '/orderstatus'}>
    Order Status
  </Button>
  <Button onClick={() => window.location.href = '/dashboard/product_orders'} autoFocus>
    View Orders 
  </Button>
  <Button onClick={() => window.location.href = '/products'} autoFocus>
    Continue Shopping 
  </Button>
</DialogActions>
</Dialog>

    </React.Fragment>
  );
}

export default Checkout;