import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function PaymentForm({ phoneNumber, setPhoneNumber, networkCode, setNetworkCode }) {
  const isValidPhoneNumber = (input) => /^254\d{9}$/.test(input);
  // const [networkCode, setNetworkCode] = React.useState('');

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value.replace(/\s/g, ''); // Remove spaces from input

    if (isValidPhoneNumber(inputValue) || inputValue === '' || inputValue.length <= 12) {
      // Update the state variable only if the input is valid, empty, or within the length limit
      setPhoneNumber(inputValue);
    } else {
      // Display an alert for the specific error
      alert('Invalid phone number. Please start with 254 followed by 9 digits.');
    }
  };
  const handleNetworkCodeChange = (e) => {
    setNetworkCode(e.target.value);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Input Mobile Number to Initiate Mobile Payment
      </Typography>
      <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="network-code-label">Payment Method</InputLabel>
            <Select
              labelId="network-code-label"
              id="network-code"
              value={networkCode}
              onChange={handleNetworkCodeChange}
            >
              <MenuItem value="63902">MPesa</MenuItem>
              <MenuItem value="63903">AirtelMoney</MenuItem>
              <MenuItem value="63907">T-Kash</MenuItem>
            </Select>
          </FormControl>
        </Grid>
  
      <Grid item xs={12} md={6}>
        <TextField
          required
          id="Indicate Payment Mobile Number"
          label="Mobile Number"
          helperText="Input as 254xxxxxxxxx"
          fullWidth
          autoComplete="cc-csc"
          variant="standard"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox color="secondary" name="saveCard" value="yes" />}
          label="Remember Mpesa Mobile Number details for next time"
        />
      </Grid>
    </React.Fragment>
  );
}
