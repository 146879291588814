import React from 'react';

import ProductTable from '../produsctstable';

const ProductsPage = () => {
  
  return (
    <ProductTable/>
  );
};

export default ProductsPage;
