import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../auth/auth';
import {
  Grid,
  Button,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Alert
} from '@mui/material';

const SubscriptionForm = () => {
  const [businesses, setBusinesses] = useState([]);
  const { authTokens, apiUrl } = useContext(AuthContext);
  const [selectedBusinessId, setSelectedBusinessId] = useState(''); // Store the business ID
  const [selectedPlan, setSelectedPlan] = useState('');
  const [message, setMessage] = useState(null);

  // Fetch businesses when the component mounts
  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/business/get/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        console.log('Fetched Businesses:', response.data); // Debugging log
        setBusinesses(response.data); // Update the state with fetched businesses
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };

    fetchBusinesses();
  }, [apiUrl, authTokens]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Selected Business ID:', selectedBusinessId); // Debugging log
    console.log('Selected Plan:', selectedPlan); // Debugging log

    if (!selectedBusinessId) {
      setMessage({ type: 'error', text: 'Please select a business' });
      return;
    }

    if (!selectedPlan) {
      setMessage({ type: 'error', text: 'Please select a subscription plan' });
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/api/subscribe/`,
        {
          business: selectedBusinessId, // Correctly use the business ID here
          plan: selectedPlan, // Include the selected plan
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      setMessage({ type: 'success', text: 'Subscription successful!' });
    } catch (error) {
      if (error.response) {
        setMessage({ type: 'error', text: error.response.data.error });
      } else {
        setMessage({ type: 'error', text: 'An error occurred, please try again.' });
      }
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <Typography variant="h4" gutterBottom align="center">
          Subscribe Your Business
        </Typography>

        {message && (
          <Alert severity={message.type} sx={{ mb: 2 }}>
            {message.text}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          {/* Business Selection */}
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Select a Business</InputLabel>
            <Select
              value={selectedBusinessId}
              onChange={(e) => {
                console.log('Business Selected:', e.target.value); // Log for debugging
                setSelectedBusinessId(e.target.value);
              }}
              label="Select a Business"
              required
            >
              <MenuItem value="">
                <em>-- Select a Business --</em>
              </MenuItem>
              {businesses.length === 0 && (
                <MenuItem disabled>
                  <em>No businesses available</em>
                </MenuItem>
              )}
              {businesses.map((business) => (
                // Use the unique `id` field instead of `id_number`
                <MenuItem key={business.id} value={business.id}>
                  {business.name} - {business.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Plan Selection */}
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Select a Plan</InputLabel>
            <Select
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              label="Select a Plan"
              required
            >
              <MenuItem value="">
                <em>-- Select a Plan --</em>
              </MenuItem>
              <MenuItem value="Monthly">Monthly Plan</MenuItem>
              <MenuItem value="Quarterly">Quarterly Plan</MenuItem>
              <MenuItem value="Half-Yearly">Half-Yearly Plan</MenuItem>
              <MenuItem value="Yearly">Yearly Plan</MenuItem>
            </Select>
          </FormControl>

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ py: 1.5 }}
          >
            Subscribe
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default SubscriptionForm;
