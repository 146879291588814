import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate,  useLocation } from 'react-router-dom';
import { Container, Typography, Divider, Button, TextField, Box } from '@mui/material';
import { FaTruck, FaShippingFast, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import Material-UI icons
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { AuthContext } from '../auth/auth';



function OrderStatus() {
  const { orderId } = useParams();
  const { cartList, authTokens } = useContext(AuthContext);
  const navigate = useNavigate(); // Use useNavigate for redirection
  const location = useLocation();
  const { apiUrl } = useContext(AuthContext);
  

  // Check if user is not logged in, navigate to login page
  useEffect(() => {
    if (!authTokens) {
      navigate('/login'); // Use navigate function to navigate
    }
  }, [authTokens, navigate]);


  const [orderDetails, setOrderDetails] = useState({});
  const [logisticsStatus, setLogisticsStatus] = useState({});
  const [feedback, setFeedback] = useState('');
  const [showLogistics, setShowLogistics] = useState(false);
  const [loadingLogistics, setLoadingLogistics] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  // Access orderNo from location state or local storage
  const orderNo = location.state?.orderNo || localStorage.getItem('orderNo');
  
   

  useEffect(() => {
    // Fetch order details and logistics status based on orderId
    // Update state with fetched data
    // This involves making API calls to your backend
  }, [orderId]);

  const handleFeedbackSubmit = () => {
    // Submit feedback to backend
    // This involves making an API call to store feedback
  };

  const getLogisticsIcon = (status) => {
    switch (status) {
      case 'disbursed':
        return <FaTruck />;
      case 'in_transit':
        return <FaShippingFast />;
      case 'delivered':
        return <FaCheckCircle />;
      case 'returned':
        return <FaTimesCircle />;
      default:
        return null;
    }
  };

 
  const toggleLogistics = async () => {
    try {
      setLoadingLogistics(true);

      // Construct the request body with the order number
      const requestBody = {
        order_number: orderNo,
      };

      // Make the API call
      const response = await fetch(`${apiUrl}/api/order_status/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();

        // Update state with fetched data
        setLogisticsStatus(data);
        setShowLogistics(!showLogistics);
        // Display the response status in a dialog
        setDialogOpen(true);
        setDialogContent(`The status of order number ${data.order_number} is ${data.status}.`);
        
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch logistics status:', errorData);
        // Handle error, display message, etc.
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      // Handle unexpected errors
    } finally {
      setLoadingLogistics(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" gutterBottom>
      Order No - #{orderNo}
      </Typography>
      <Divider />

      {/* Display order details and payment status */}
      <Typography variant="subtitle1">Order Details</Typography>
      <List disablePadding>
        {cartList.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <img src={product.image1} alt={product.title} style={{ maxWidth: '100px' }} />
            <ListItemText primary={product.title} secondary={product.description} />
            <Typography variant="body2">{`Ksh ${product.price}`}</Typography>           
          </ListItem>
        ))}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>          
            {`Ksh ${cartList.reduce((total, product) => total + parseFloat(product.price), 0).toFixed(2)}`}
          </Typography>
        </ListItem>
      </List>
      {/* Display order details here */}
      <Divider />

      {/* Display logistics status */}
      <Typography variant="subtitle1">Logistics Status</Typography>

      {/* Display logistics status for each product */}
      <Button variant="outlined" color="primary" onClick={toggleLogistics}>
        {loadingLogistics ? 'Loading...' : 'Show Logistics Status'}
      </Button>

      {showLogistics && logisticsStatus.products &&
        logisticsStatus.products.map((product, index) => (
          <Box key={index} display="flex" alignItems="center">
            {getLogisticsIcon(product.status)} {/* Display the appropriate icon */}
            <Typography>{product.name}</Typography>
          </Box>
        ))}
      <Divider />
      

      {/* Feedback section */}
      <Typography variant="subtitle1">Give Feedback</Typography>
      <TextField
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        placeholder="Enter your feedback here..."
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleFeedbackSubmit}>
          Submit Feedback
        </Button>
      </Box>
      {/* Dialog for displaying response status and order_number */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Logistics Status</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog> 
    </Container>
  );
}

export default OrderStatus;