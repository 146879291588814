import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Linkify from 'react-linkify'; 
import './Rasabot.css'; 

function Rasabot() {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const chatContainerRef = useRef(null);
  const [isWidgetVisible, setIsWidgetVisible] = useState(false);
  const [isBotTyping, setIsBotTyping] = useState(false);

  // const rasaServerUrl = 'http://localhost:5005/webhooks/rest/webhook';
  const rasaServerUrl = 'https://jikonnectsoko.go.ke/webhooks/rest/webhook';

  const sendMessage = (message) => {
    setIsBotTyping(true);
    fetch(rasaServerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: message, sender: 'user' }),
    })
    .then(response => response.json())
    .then(data => {
      setTimeout(() => {
        data.forEach(message => {
          displayBotMessage(message);
        });
        setIsBotTyping(false);
      }, 500); // Simulate a 500ms delay
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsBotTyping(false);
    });
  };

  const displayBotMessage = (message) => {
    setMessages(prevMessages => [
      ...prevMessages,
      { type: 'bot', text: message.text, buttons: message.buttons, image: message.image }
    ]);
  };

  const displayUserMessage = (message) => {
    setMessages(prevMessages => [
      ...prevMessages,
      { type: 'user', text: message }
    ]);
  };

  const handleSendClick = () => {
    if (userInput.trim() !== '') {
      displayUserMessage(userInput);
      sendMessage(userInput);
      setUserInput('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendClick();
    }
  };

  const handleButtonClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div>
      <div className="profile_div" onMouseEnter={() => document.getElementById('welcomeMessage').style.display = 'block'} onMouseLeave={() => document.getElementById('welcomeMessage').style.display = 'none'} onClick={() => setIsWidgetVisible(!isWidgetVisible)}>
        <img
          className="imgProfile"
          src="https://icon2.cleanpng.com/20240319/ogu/transparent-mustache-smiling-black-man-in-yellow-jacket-1711068213021.webp"
          alt="Chatbot Icon"
        />
        <div id="welcomeMessage" className="welcomeMessage">
          Hello, I'm Mr.Soko. How may I help you today?
        </div>
      </div>

      <div className="widget" id="widget" style={{ display: isWidgetVisible ? 'block' : 'none' }}>
        <div className="chat_header">
          <span className="chat_header_title">Mr.Soko</span>
        </div>

        <div className="chats" ref={chatContainerRef}>
          {messages.map((msg, index) => (
            <div key={index} className={`chat ${msg.type}`}>
              <div className="icon"></div>
              <div className="message">
                <Linkify>
                  {msg.text}
                </Linkify>
              </div>
              {msg.buttons && (
                <div className="buttons">
                  {msg.buttons.map((button, btnIndex) => (
                    <button key={btnIndex} className="button" onClick={() => handleButtonClick(button.payload)}>
                      {button.title}
                    </button>
                  ))}
                </div>
              )}
              {msg.image && <img src={msg.image} style={{ width: '100%' }} alt="Bot" />}
            </div>
          ))}
          {isBotTyping && (
            <div className="botTyping">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          )}
        </div>

        <div className="keypad">
          <textarea
            id="userInput"
            placeholder="Type a message..."
            className="usrInput"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyDown}
          ></textarea>
          <div id="sendButton" onClick={handleSendClick}>
            <i className="fa fa-paper-plane" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rasabot;