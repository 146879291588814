import React, { useState, useEffect, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, TextField, InputAdornment, Button, Dialog, DialogTitle, DialogActions, DialogContent, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/auth';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaTruck, FaShippingFast, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

export default function OrderTable() {
  const [orders, setOrders] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false); 
  const { authTokens, dashboardtype, logoutUser, apiUrl } = useContext(AuthContext);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false); // State for order status dialog
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null); 

  // Handle row click to open dialog
  const handleRowClick = (product) => {
    if (!product) return;
    // Open the dialog to confirm navigation to the detail page
    setSelectedProduct(product);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    const productName = selectedProduct.product_serial;
    navigate(`/dashboard/products&services/${productName}`);
  };

  // Fetch orders from API on load
  useEffect(() => {
    let followUrl;
    if (dashboardtype === "Customer") {
      followUrl = "product_orders";
    } else if (dashboardtype === "Merchant") {
      followUrl = "merchant/product_orders";
    } else if (dashboardtype === "Admin") {
      followUrl = "admin/product_orders";
    }

    if (followUrl) {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
      };

      fetch(`${apiUrl}/api/${followUrl}/`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json().then((data) => {
              const ordersWithId = data
                .map((product, index) => ({ id: index + 1, ...product }))
                .sort((a, b) => new Date(b.created) - new Date(a.created));
              setOrders(ordersWithId);
              setFilteredRows(ordersWithId);
            });
          } else if (response.statusText === 'Unauthorized') {
            logoutUser();
          }
        })
        .catch((error) => console.error(error));
    }
  }, [dashboardtype, authTokens, apiUrl, logoutUser]);

  // Handle search functionality
  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filtered = orders.filter(
      (product) =>
        product.customer_name.toLowerCase().includes(keyword) ||
        product.customer_phone.toLowerCase().includes(keyword) ||
        product.business_name.toLowerCase().includes(keyword) ||
        product.business_phonenumber.toLowerCase().includes(keyword) ||
        product.product_serial.toLowerCase().includes(keyword) ||
        product.product_title.toLowerCase().includes(keyword)
    );
    setFilteredRows(filtered);
  };

  // Fetch order status from API when button is clicked
  const handleCheckOrderStatus = async (product) => {
    console.log('Checking status for product:', product);
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({ order_number: product.order_no }),
    };

    try {
      const response = await fetch(`${apiUrl}/api/order_status/`, requestOptions);
      if (response.ok) {
        const result = await response.json();
        setOrderStatus(result.status); // Set the fetched status
        setStatusDialogOpen(true); // Open the status dialog
      } else {
        console.error('Failed to fetch order status');
        setOrderStatus('Error fetching status');
        setStatusDialogOpen(true); // Open the status dialog
      }
    } catch (error) {
      console.error('Error:', error);
      setOrderStatus('Error fetching status');
      setStatusDialogOpen(true); // Open the status dialog
    } finally {
      setLoading(false);
    }
  };

  // Close the order status dialog
  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false);
  };

  // Columns for the DataGrid
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'product_image',
      headerName: 'Image',
      width: 120,
      renderCell: (params) => (
        <img src={params.value} alt="" style={{ width: '50px', height: '50px' }} />
      ),
    },
    { field: 'product_title', headerName: 'Name', width: 150 },
    { field: 'customer_name', headerName: 'Customer Name', width: 150 },
    { field: 'customer_phone', headerName: 'Customer Phone', width: 150 },
    { field: 'business_name', headerName: 'Business', width: 150 },
    { field: 'business_phonenumber', headerName: 'Business Phone', width: 150 },
    { field: 'product_serial', headerName: 'Product Serial', width: 150 },
    { field: 'created', headerName: 'Created', width: 200 },
    { field: 'updated', headerName: 'Updated', width: 200 },
    {
      field: 'order_status',
      headerName: 'Order Status',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            handleCheckOrderStatus(params.row);
          }}
        >
          Check Status
        </Button>
      ),
    },
  ];

  return (
    <Box height="auto" width="75%">
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Grid item>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <DataGrid
        rows={filteredRows}
        columns={columns}
        autoHeight
        pageSize={10}
        onRowClick={(params) => handleRowClick(params.row)}
        pagination
      />

      {loading && <CircularProgress />}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Proceed to {selectedProduct && selectedProduct.title} Detail Page?</DialogTitle>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>

      {/* Order Status Dialog */}
      <Dialog open={statusDialogOpen} onClose={handleStatusDialogClose}>
        <DialogTitle>Order Status</DialogTitle>
        <DialogContent>
        {orderStatus ? (
          <p>Order Status: {orderStatus}</p>
        ) : (
          <p style={{ color: 'red' }}>Order Status is null</p>
        )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
