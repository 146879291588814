import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const MerchantOrderForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    businessName: '',
    county: '',
    subcounty: '',
    locality: '',
    approximateLocation: '',
    nearestPostOffice: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleApproximateLocationChange = (event, newValue) => {
    setFormData({ ...formData, approximateLocation: newValue });
  };

  const handleNearestPostOfficeChange = (event, newValue) => {
    setFormData({ ...formData, nearestPostOffice: newValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission, e.g., send data to the server
    console.log('Form Data:', formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Business Name"
            name="businessName"
            value={formData.businessName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="County"
            name="county"
            value={formData.county}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Subcounty"
            name="subcounty"
            value={formData.subcounty}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Locality"
            name="locality"
            value={formData.locality}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={[]} // Add your Google Maps autocomplete options here
            getOptionLabel={(option) => option.description}
            renderInput={(params) => (
              <TextField {...params} label="Approximate Location" />
            )}
            onChange={handleApproximateLocationChange}
            value={formData.approximateLocation}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={[]} // Add your nearest post office autocomplete options here
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Nearest Post Office" />
            )}
            onChange={handleNearestPostOfficeChange}
            value={formData.nearestPostOffice}
          />
        </Grid>
      </Grid>
      <button type="submit">Submit</button>
    </form>
  );
};

export default MerchantOrderForm;
