import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';

const Partners = () => {
  const containerStyle = {
    padding: '40px', // Add more padding for better spacing
    textAlign: 'center', // Center-align content
  };

  const headerStyle = {
    fontSize: '32px', // Increase the font size for the header
    fontWeight: 'bold', // Make it bold
    marginBottom: '20px', // Add some margin at the bottom
  };

  const descriptionStyle = {
    fontSize: '18px', // Increase the font size for the description
    margin: '20px 0', // Add margin above and below the description
  };

  const buttonStyle = {
    fontSize: '20px', // Increase the font size for the button
    padding: '12px 24px', // Add more padding to the button
  };

  return (
    <Container style={containerStyle}>
      <Typography variant="h4" style={headerStyle}>
        Partners
      </Typography>
      <Typography variant="body1" style={descriptionStyle}>
        Are you a merchant/seller with a high turnover in online sales and would like to scale up your operations by having a customized online store?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component="a"
        href="https://cartnshop.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={buttonStyle}
      >
        Subscribe to CartnShop
      </Button>
    </Container>
  );
};

export default Partners;
