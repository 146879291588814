import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { AuthContext } from '../auth/auth';
import { useNavigate } from 'react-router-dom';
import { Autocomplete } from '@react-google-maps/api';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { branchesData } from './posta/data';
import PostaAutoComplete from './posta/field';


const ProductServicePage = () => {
  const [postData, setPostData] = useState({});
  const { updateCategoryName ,apiUrl } = useContext(AuthContext);
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});  
  const [selectedType, setSelectedType] = useState('');
  const [selectedAppName, setSelectedAppName] = useState('');
  const [dynamicFields, setDynamicFields] = useState([]);
  const [nestedFields, setNestedFields] = useState({});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedChosenType, setSelectedChosenType] = useState('');
  const [showRemainingFields, setShowRemainingFields] = useState(false);
  const [counties, setCounties] = useState([]);
  const [subCounties, setSubCounties] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState('');
  const [selectedSubCounty, setSelectedSubCounty] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState('');
  const [selectedNearestPostOffice, setNearestPostOffice] = useState('');
  // const [selectedPostaBranch, setSelectedPostaBranch] = useState(null);

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const onLoad = (autocompleteInstance) => setAutocomplete(autocompleteInstance);
  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      setSelectedRegion(place.formatted_address || '');
    }
  };


  const navigate=useNavigate()


  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedType(selectedType);
    setSelectedAppName('');
    setSelectedCategory('');
    setSelectedSubcategory(''); 
    setSelectedCounty('');
    setSelectedRegion('');
    setTypes([]);
    setDynamicFields([]);
    setShowRemainingFields(false);
    setPostData((prevPostData) => ({
      ...prevPostData,
      selectedType: selectedType,
      selectedAppName: '',
      selectedCategory: '',
      selectedSubcategory: '',
      image1:'',
      image2:'',
      image3:'',
      image4:'',
      image5:'',
    }));
    
  
    if (data[selectedType]) {
      const appData = data[selectedType].find((item) => item.app_name === selectedAppName);
      if (appData) {
        const selectedCategoryObj = appData.models[0].related_objects.category.find(
          (category) => category.category === selectedCategory
        );
        if (selectedCategoryObj) {
          const selectedSubcategoryObj = selectedCategoryObj.subcategories.find(
            (subcategory) => subcategory.subcategory === selectedSubcategory
          );
          if (selectedSubcategoryObj) {
            setTypes(selectedSubcategoryObj.types);
            populateFields(selectedAppName);
          }
        }
      }
    }
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleNearestPostOfficeChange = (event) => {
    console.log('Event Target Value:', event.target.value); // Check what value is coming from the event

    const nearestPostOffice = branchesData.branches.find(branch => branch.id === event.target.value);
    
    if (nearestPostOffice) {
        const nearestPostOfficeName = nearestPostOffice.name;
        console.log('Nearest Post Office Found:', nearestPostOfficeName); // Confirm the correct office is found
        setNearestPostOffice(nearestPostOfficeName);
      
        // // Update the postData state with the selectedNearestPostOffice value
        // setPostData((prevPostData) => ({
        //     ...prevPostData,
        //     nearestPostOffice: nearestPostOfficeName, 
        // })
    }

    else {
        console.error('No matching post office found for the given ID'); // Log error if no match is found
    }
};



  useEffect(() => {
    const loadGoogleMapsScript = async () => {
      try {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDDZe20AaxH_oIvJvQcksysTuMHhkf_X50&libraries=places`;
        script.async = true;
        script.onload = () => {
          setScriptLoaded(true);
        };
        document.head.appendChild(script);
      } catch (error) {
        console.error('Error loading Google Maps API script:', error);
      }
    };
  
    loadGoogleMapsScript();
  }, []);



  useEffect(() => {
    console.log(postData); // Log the postData value whenever it changes
  }, [postData]);
  
  
  
  const handleAppNameChange = (event) => {
    const selectedAppName = event.target.value;
    setSelectedAppName(selectedAppName);
    setSelectedCategory('');
    setSelectedSubcategory(''); 
    setSelectedSubcategory(''); 
    setSelectedCounty('');
    setSelectedRegion('');
    setTypes([]);
    setDynamicFields([]);
    setShowRemainingFields(false);
    setPostData((prevPostData) => ({
      ...prevPostData,
      selectedAppName: selectedAppName,
      image1:'',
      image2:'',
      image3:'',
      image4:'',
      image5:'',
    }));
      
    if (data[selectedType]) {
      const appData = data[selectedType].find((item) => item.app_name === selectedAppName);
      if (appData) {
        setCategories(appData.models[0].related_objects.category);
        setCounties(appData.models[0].related_objects.locality)
        populateFields(selectedAppName);
      }
    }
  };  
  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);
    setSelectedSubcategory(''); 
    setTypes([]);
    setDynamicFields([]);
    setShowRemainingFields(false);
    setPostData((prevPostData) => ({
      ...prevPostData,
      category: selectedCategory,
      image1:'',
      image2:'',
      image3:'',
      image4:'',
      image5:'',
    }));
     
    
    if (data[selectedType]) {
      const appData = data[selectedType].find((item) => item.app_name === selectedAppName);
      if (appData) {
        const selectedCategoryObj = appData.models[0].related_objects.category.find(
          (category) => category.category === selectedCategory
        );
        if (selectedCategoryObj) {
          setSubcategories(selectedCategoryObj.subcategories);
        }
      }
    }
  };  
  const handleSubcategoryChange = (event) => {
    const selectedSubcategory = event.target.value;
    setSelectedSubcategory(selectedSubcategory); 
    setTypes([]);
    setDynamicFields([]); 
    setShowRemainingFields(false);
    setPostData((prevPostData) => ({
      ...prevPostData,
      subcategory: selectedSubcategory,
      image1:'',
      image2:'',
      image3:'',
      image4:'',
      image5:'',
    }));
    
    if (data[selectedType]) {
      const appData = data[selectedType].find((item) => item.app_name === selectedAppName);
      if (appData) {
        const selectedSubcategoryObj = appData.models[0].related_objects.category
          .find((category) => category.category === selectedCategory)
          .subcategories.find((subcategory) => subcategory.subcategory === selectedSubcategory);
        if (selectedSubcategoryObj) {
          setTypes(selectedSubcategoryObj.types); // Fixed: Update 'types' state
          populateFields(selectedAppName);
        }
      }
    }
  };
  const handleCountyChange = (event) => {
    const selectedCounty = event.target.value;
    setSelectedCounty(selectedCounty);
    setSelectedSubCounty('');
    setSelectedRegion('');
  
    if (data[selectedType]) {
      const appData = data[selectedType].find((item) => item.app_name === selectedAppName);
      if (appData) {
        const selectedCountyObj = appData.models[0].related_objects.locality.find(
          (county) => county.county === selectedCounty
        );
        if (selectedCountyObj) {
          setSubCounties(selectedCountyObj.subcounties); // Corrected: Update 'subcounties' state
        }
      }
    }
  };
  
  
  const handleSubCountyChange = (event) => {
    const selectedSubCounty = event.target.value;
    setSelectedSubCounty(selectedSubCounty);
    setSelectedRegion('');
  
    if (data[selectedType]) {
      const appData = data[selectedType].find((item) => item.app_name === selectedAppName);
  
      if (appData && appData.models[0].related_objects.locality) {
        const selectedCountyObj = appData.models[0].related_objects.locality.find(
          (locality) => locality.county === selectedCounty
        );
  
        if (selectedCountyObj) {
          const selectedSubCountyObj = selectedCountyObj.subcounties.find(
            (subcounty) => subcounty.subcounty === selectedSubCounty
          );
  
          if (selectedSubCountyObj) {
            setRegions(selectedSubCountyObj.localities);
          }
        }
      }
    }
  };
  
  
  
  const handleRegionChange = (event) => {
    const chosen=event.target.value
    setSelectedRegion(chosen);
    setPostData((prevPostData) => ({
      ...prevPostData,
      locality: chosen,
      
    }));
  };
  const handleSelect = async (address) => {
    setAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setSelectedRegion(address);
      setPostData((prevPostData) => ({
        ...prevPostData,
        locality: address,
        // You may also save latLng.latitude and latLng.longitude if needed
      }));
    } catch (error) {
      console.error('Error selecting address', error);
    }
  };
  
    
  const populateFields = (appName) => {
    const selectedProduct = data[selectedType].find((product) => product.app_name === appName);  
    if (selectedProduct) {
      const fields = selectedProduct.models[0].fields
        .filter((field) => !['locality','category', 'subcategory', 'type'].includes(field))
        .map((field) => {
          let type = 'text';
          let options = null;
  
          if (field.toLowerCase().includes('image')) {
            type = 'attachment';
          } else if (field.toLowerCase().includes('is') || field.toLowerCase().includes('negotiable')) {
            type = 'checkbox';
          } else {
            const relatedObjectField = Object.keys(selectedProduct.models[0].related_objects).find(
              (relatedField) => field.toLowerCase() === relatedField.toLowerCase()
            );
  
            if (relatedObjectField) {
              type = 'select';
  
              const relatedOptions = selectedProduct.models[0].related_objects[relatedObjectField];
              options = relatedOptions.map((option) => (option.name ? option.name : option));
            }
          }
  
          return {
            name: field,
            type: type,
            options: options,
          };
        });
      setDynamicFields(fields);
    }
  };  

  const handleChosenTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedChosenType([selectedType]);
    setPostData((prevPostData) => ({
      ...prevPostData,
      type: selectedType,
      image1:'',
      image2:'',
      image3:'',
      image4:'',
      image5:'',
    }));
    
    setShowRemainingFields(true);
  };
  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, files, type, value, checked } = event.target;
  
    // Check if it's a file input
    if (type === 'file') {
      if (files && files.length > 0) {
        const selectedFile = files[0];
        const allowedExtensions = ['.jpg', '.jpeg', '.heic', '.webp', '.png'];
        const fileExtension = selectedFile.name.substring(selectedFile.name.lastIndexOf('.'));
        if (allowedExtensions.includes(fileExtension.toLowerCase())) {
          setPostData((prevPostData) => ({
            ...prevPostData,
            [name]: selectedFile,
          }));
          alert(`Selected file: ${selectedFile.name}`);
        } else {
          event.target.value = null;
          alert('Invalid file type. Only JPG, JPEG, HEIC, and WEBP images are allowed.');
        }
      }
    } else {
      // Handle other input types
      let fieldValue = value;
      if (type === 'checkbox') {
        fieldValue = checked;
      }
      setPostData((prevPostData) => ({
        ...prevPostData,
        [name]: fieldValue,
      }));
    }
  };
  
  
    
  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors({});
  
    const formdata = new FormData();
    formdata.append('approximateLocation', selectedRegion);
    formdata.append('nearestPostOffice', selectedNearestPostOffice);
  
    Object.keys(postData).forEach((key) => {
      if (postData[key] instanceof File) {
        formdata.append(key, postData[key]); // Add file inputs
      } else {
        formdata.append(key, postData[key]); // Add other inputs
      }
    });
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept-Encoding': 'gzip, deflate, br',
        'Accept': '*/*',
        'Authorization': `Bearer ${authTokens.access}`,
      },
      body: formdata,
    };
  
    fetch(`${apiUrl}/api/${postData.selectedAppName}/create/`, requestOptions)
      .then((response) => {
        if (response.status === 400) {
          return response.json().then((data) => {
            setErrors(data);
          });
        } else if (response.status === 200) {
          return response.json().then((data) => {
            navigate("/dashboard");
          });
        } else {
          return response.json();
        }
      })
      .then((data) => console.log(data))
      .catch((error) => console.error(error));
  };
  
  // UseEffect to fetch data
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch(`${apiUrl}/api/productsandservices/`, requestOptions)
      .then((response) => {
        if (response.status === 400) {
          return response.json().then((data) => {
            setErrors(data);
          });
        } else if (response.status === 200) {
          return response.json().then((data) => {
            setData(data);
          });
        } else if (response.status === 403) {
          return response.json().then((data) => {
            setErrors(data);
          });
        } else if (response.status === 405) {
          return response.json().then((data) => {
            setErrors(data);
          });
        } else {
          return response.json();
        }
      });
  }, []);
  

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <Typography color="success">CREATE PRODUCT || SERVICE</Typography>
      <Box>
        <FormControl fullWidth>
          <InputLabel>Select Product or Service</InputLabel>
          <Select value={selectedType} onChange={handleTypeChange}>
            <MenuItem value="">Select Product or Service</MenuItem>
            <MenuItem value="products">Product</MenuItem>
            <MenuItem value="services">Service</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {selectedType && (
        <Box>
          <TextField
            fullWidth
            select
            label="App Name"
            value={selectedAppName}
            onChange={handleAppNameChange}
            error={Boolean(errors.appname)}
            helperText={errors.appname}
          >
            {data[selectedType]?.map((item) => (
              <MenuItem key={item.app_name} value={item.app_name}>
                {item.app_name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {selectedAppName && categories.length > 0 && (
        <Box>
          <TextField
            fullWidth
            select
            label="Category"
            value={selectedCategory}
            onChange={handleCategoryChange}
            name="category"
            error={Boolean(errors.category)}
            helperText={errors.category}
          >
            {categories.map((category) => (
              <MenuItem key={category.category} value={category.category}>
                {category.category}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {selectedCategory && subcategories.length > 0 && (
        <Box>
          <TextField
            fullWidth
            select
            label="Subcategory"
            value={selectedSubcategory}
            onChange={handleSubcategoryChange}
            error={Boolean(errors.subcategory)}
            helperText={errors.subcategory}
          >
            {subcategories.map((subcategory) => (
              <MenuItem key={subcategory.subcategory} value={subcategory.subcategory}>
                {subcategory.subcategory}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {selectedSubcategory && types.length > 0 && (
        <Box>
          <TextField
            fullWidth
            select
            variant="outlined"
            label="Type"
            value={selectedChosenType}
            onChange={handleChosenTypeChange}
            error={Boolean(errors.type)}
            helperText={errors.type}
          >
            {types.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {dynamicFields.map((field) => (
        <Box key={field.name}>
          {field.type === 'attachment' && (
            <input
              type="file"
              name={field.name}
              label={field.name}
              onChange={handleInputChange}
              accept="image/jpeg,image/png,image/heic"
              error={Boolean(errors[field.name])}
              helperText={errors[field.name]}
            />
          )}
          {field.type === 'text' && (
            <TextField
              type="text"
              name={field.name}
              label={field.name}
              fullWidth
              variant="outlined"
              error={Boolean(errors[field.name])}
              helperText={errors[field.name]}
              value={postData[field.name] || ''}
              onChange={handleInputChange}
            />
          )}
          {field.name === 'Product Weight' && (
            <Box>
              <TextField
                fullWidth
                select
                label="Weight Metric"
                value={postData.weight_metric || 'g'}
                onChange={handleInputChange}
                name="weight_metric"
              >
                <MenuItem value="g">Grams</MenuItem>
                <MenuItem value="kg">Kilograms</MenuItem>
                <MenuItem value="tn">Tonnes</MenuItem>
              </TextField>
            </Box>
          )}
          {field.type === 'select' && (
            <FormControl fullWidth>
              <InputLabel>{field.name}</InputLabel>
              <Select
                value={postData[field.name] || ''}
                onChange={handleInputChange}
                name={field.name}
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]}
              >
                <MenuItem value="">Select an option</MenuItem>
                {field.options?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {field.type === 'number' && (
            <TextField
              type="number"
              name={field.name}
              label={field.name}
              fullWidth
              variant="outlined"
              error={Boolean(errors[field.name])}
              helperText={errors[field.name]}
              value={postData[field.name] || ''}
              onChange={handleInputChange}
            />
          )}
          {field.type === 'checkbox' && (
            <FormControlLabel
              control={
                <Checkbox
                  name={field.name}
                  checked={postData[field.name] || false}
                  onChange={handleInputChange}
                />
              }
              label={field.name}
            />
          )}
        </Box>
      ))}
    <Box sx={{ width: '100%', margin: '0 auto', padding: 0 }}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="nearest-post-office-label">Nearest Post Office</InputLabel>
        <Select
          labelId="nearest-post-office-label"
          onChange={handleNearestPostOfficeChange}
          label="Nearest Post Office"
        >
          {branchesData.branches.map(branch => (
            <MenuItem key={branch.id} value={branch.id}>
              {branch.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
      


      {/* <Box>
        <PostaAutoComplete
          branchesData={branchesData}
          setSelectedPostaBranch={setNearestPostOffice}
          onChange={handleNearestPostOfficeChange}
          value={selectedNearestPostOffice}
        />
      </Box> */}
      {/* <PostaAutoComplete
        branchesData={branchesData}
        setSelectedPostaBranch={setNearestPostOffice}
        onChange={handleNearestPostOfficeChange}
        value={selectedNearestPostOffice}
      /> */}


      {selectedAppName && counties.length > 0 && (
        <Box>
          <TextField
            fullWidth
            select
            label="County"
            value={selectedCounty}
            onChange={handleCountyChange}
            name="county"
            error={Boolean(errors.county)}
            helperText={errors.county}
          >
            {counties.map((county) => (
              <MenuItem key={county.county} value={county.county}>
                {county.county}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {selectedCounty && subCounties.length > 0 && (
        <Box>
          <TextField
            fullWidth
            select
            label="Sub County"
            value={selectedSubCounty}
            onChange={handleSubCountyChange}
            name="subcounty"
            error={Boolean(errors.subcounty)}
            helperText={errors.subcounty}
          >
            {subCounties.map((subcounty) => (
              <MenuItem key={subcounty.subcounty} value={subcounty.subcounty}>
                {subcounty.subcounty}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

     {scriptLoaded && (
        <Box>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <TextField
              fullWidth
              label="Approximate Location"
              value={selectedRegion}
              onChange={(e) => setSelectedRegion(e.target.value)}
              name="locality"
              error={Boolean(errors.locality)}
              helperText={errors.locality}
            />
          </Autocomplete>
        </Box>
      )}


      {/* <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              fullWidth
              label="Approximate Location"
              {...getInputProps({ placeholder: 'Search Places ...', className: 'location-search-input' })}
              error={Boolean(errors.locality)}
              helperText={errors.locality}
              onChange={handleAddressChange}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const style = { backgroundColor: suggestion.active ? '#41b6e6' : '#fff' };
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete> */}

      <Box>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </form>
  );
};
export default ProductServicePage;

            