import React, { useState } from 'react';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const stripePromise = loadStripe('pk_test_51OHJr4CMYbe6vy3PP45LrZWIx1RUVyKhc4HbJWdFL1bFWaygvYnQOMzlgYMD7VYJv9XqFnhxlDyXu2sLssud5gvO002C3AHelJ');

const StripePaymentForm = ({ paymentMethod, setPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      // You can send the paymentMethod.id to your server to complete the payment on the backend
      console.log('Payment Method:', paymentMethod);
      setPaymentMethod(paymentMethod);
      setPaymentError(null);
    } catch (error) {
      console.error(error);
      setPaymentError('Payment failed. Please check your card details and try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
      {/* Display any payment errors */}
      {paymentError && (
        <Typography variant="body2" color="error" style={{ marginBottom: '16px' }}>
          {paymentError}
        </Typography>
      )}

      {/* Stripe CardElement */}
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />

      {/* Submit Button */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!stripe}
        sx={{ marginTop: '16px' }}
      >
        Pay Now
      </Button>
    </form>
  );
};

const PaymentOptions = ({ setPaymentMethod }) => {
    // Add logic to handle different payment methods
  
    const buttonStyles = {
      margin: '8px',
      width: '100%', // Set width to 100%
    };
  
    return (
      <div style={{ maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Payment Options
        </Typography>
        <Typography paragraph>
          Choose your preferred payment method:
        </Typography>
        <Button variant="outlined" onClick={() => setPaymentMethod('card')} sx={buttonStyles}>
          Cards or Bank Transfer
        </Button>
        <Button variant="outlined" onClick={() => setPaymentMethod('bankTransfer')} sx={buttonStyles}>
          Mobile Money
        </Button>
        <Button variant="outlined" onClick={() => setPaymentMethod('ecitizen')} sx={buttonStyles}>
          Ecitizen payment
        </Button>
      </div>
    );
  };

const PaymentDetails = () => {
  return (
    <div style={{ maxWidth: '400px', margin: '0 auto' }}>
      <Typography paragraph>
        We accept:
      </Typography>
      <Typography paragraph>
        (M-Pesa, Airtel or Bank Cards)
      </Typography>
      <Typography paragraph>
        You will be redirected to the Ecitizen platform to complete your purchase.
       
      </Typography>
          </div>
  );
};

const StripePayment = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);

  return (
    <Elements stripe={stripePromise}>
      <PaymentOptions setPaymentMethod={setPaymentMethod} />
      {paymentMethod === 'card' && <StripePaymentForm paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />}
      <PaymentDetails />
    </Elements>
  );
};

export default StripePayment;
